import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import queryString from 'query-string';
import Layout from "../layouts"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Loader from "../components/loader"
import Search from "../components/search"

class AllCatalog extends Component {
  constructor(props) {
    super(props)
    const { location } = this.props
    var initialState = {
      products: [],
      initialLoad: false,
      loading: true,
      search: "",
      order: "",
      orderBy: "",
      categories: [],
      tag: "",
      currentPage: 1,  // Current page number
      itemsPerPage: 10, // Number of items per page
      totalPages: 1 // Total number of pages
    }

    if (location.search) {
      const queriedFilter = queryString.parse(location.search)
      const { filter } = queriedFilter

      initialState.categories = [filter]
      initialState.tag = ""
      initialState.orderBy = "title"
      initialState.order = "asc"
    } else {
      initialState.tag = "new"
    }
    this.state = initialState
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.fetchResults = this.fetchResults.bind(this)
    this.renderFilms = this.renderFilms.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.fetchResults()
    })
  }

  htmlDecode(str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }

  getSearchParam(url) {
    var captured = /search=([^&]+)/.exec(url);  // Value is in [1] ('384' in our case)
    var result = captured ? captured[1] : '';

    return result;
  }

  fetchResults() {
    const { data } = this.props
    const { search, categories, tag, order, orderBy, initialLoad, currentPage, itemsPerPage } = this.state

    let url = `https://${data.site.siteMetadata.apiUrl}/wp-json/wp/v2/products`

    var params = "?"
    var unlimit = false 
    var reverse = false 
    if (search !== "") {
      unlimit = true
      params += params.length > 1 ? "&": ""
      params += "search=" + search
    }
    if (tag !== "" && !unlimit) {
      params += params.length > 1 ? "&": ""
      if (tag == "sales") {
        params += "xtag=" + "markets-festivals"
      } else {
        params += "tag=" + tag
      }
    }
    if (order !== "" || orderBy !== "") {
      params += params.length > 1 ? "&": ""
      params += "orderby=" + orderBy + "&order=" + order
    } else {
      reverse = true 
      params += params.length > 1 ? "&": ""
      params += "product_field=product_id&order=desc"
    }
    if (categories.length > 0) {
      params += params.length > 1 ? "&": ""
      unlimit = true
      params += "category=" + categories.join()
    }

    params += "&page=" + currentPage + "&per_page=" + itemsPerPage
    url += params
    console.log("URL");
    console.log(url);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json().then(data => {
          console.log("totalPages: ");
          console.log(parseInt(response.headers.get('X-WP-TotalPages'), 10));
          return {
            items: data,
            totalPages: parseInt(response.headers.get('X-WP-TotalPages'), 10)
          };
        });
      })
      .then(result => {
        let update = {
          products: Array.isArray(result.items) ? result.items : [],
          loading: false,
          totalPages: result.totalPages
        }
        if (!initialLoad) {
          update.initialLoad = true
        }
        this.setState(update)
      })
      .catch(error => {
        console.error('Fetch error:', error);
        this.setState({ loading: false });
      });
  }

  handlePageChange(pageNumber) {
    const { totalPages } = this.state;
    if (pageNumber > 0 && pageNumber <= totalPages) {
      this.setState({ currentPage: pageNumber, loading: true }, () => {
        this.fetchResults()
      });
    }
  }

  handleReset(update) {
    this.setState({
      loading: true,
      tag: update.activeTag,
      categories: [],
      currentPage: 1
    }, () => {
      this.fetchResults()
    })
  }

  handleSort(orderBy, order) {
    this.setState({
      loading: true,
      order: order,
      orderBy: orderBy,
      tag: "",
      currentPage: 1
    }, () => {
      this.fetchResults()
    })
  }

  handleFilter(filters, tag) {
    this.setState({
      loading: true,
      categories: filters,
      tag: tag,
      currentPage: 1
    }, () => {
      this.fetchResults()
    })
  }

  handleInputChange(event) {
    const query = event.target.value
    if (query !== undefined) {
      this.setState({
        loading: true,
        search: query,
        currentPage: 1
      }, () => {
        this.fetchResults()
      })
    }
  }

  renderFilms() {
    const { products } = this.state
    if (products.length === 0) {
      return null;
    }
    const filteredData = products.map(item => {
      let film = item.node ? item.node : item;
      var img = film.acf.product_poster_image.sizes.medium_large;
      if (img == null) {
        return null;
      } else {
        return {
          img: img, slug: film.slug, title: this.htmlDecode(film.title.rendered), dir: film.acf.product_director.toLowerCase(), badge: film.acf.product_new_badge
        }
      }
    }).filter(item => item !== null);

    let films = filteredData.map((film, index) =>
      <div className="catalog-item" key={film.slug + index}>
        <a href={"/" + film.slug}>
          <div className={film.badge ? "catalog-media catalog__new" : "catalog-media"}>
            {film.img === false ? <div className="catalog__placeholder"></div> : <img src={film.img} />}
          </div>
          <div className="catalog-details">
            <div className="catalog__title">{film.title}</div>
            <div className="catalog__director">Directed By: {film.dir}</div>
          </div>
        </a>
      </div>
    );
    return films;
  }

  renderPagination() {
    const { currentPage, totalPages } = this.state;
    if ( totalPages > 1) {
      return (
        <div className="catalog-pagination">
          <button type="button" className="slick-arrow slick-prev" onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <span>{currentPage} / {totalPages}</span>
          <button type="button" className="slick-arrow slick-next" onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
      )
    } else {
      return null
    }
    
  }

  render() {
    const { data, context } = this.props
    const { loading, categories, tag } = this.state
    const hero = data.wordpressPage.acf.catalog_hero_image.localFile.childImageSharp.fluid;

    return (
      <Layout page="catalog">
        <SEO title="Visit Films - Catalog" />
        <section className="section hero hero--catalog">
          <div className="container">
            <div className="hero__item">
              {hero !== null ? <Img src={hero.src} fluid={hero} /> : null}
              <h1 className="hero__header"><div>Catalog</div></h1>
            </div>
          </div>
        </section>
        <section className="section section-catalog">
          <div className="container">
            <Search onFilter={this.handleFilter} onReset={this.handleReset} onSort={this.handleSort} onSearch={this.handleInputChange} tag={tag} filters={categories} categories={data.allWordpressCategory.edges} context={context} />
            {loading ?
              <Loader /> :
              <>
                <div className="catalog-grid">
                  {this.renderFilms()}
                </div>
                {this.renderPagination()}
              </>
            }
          </div>
        </section>
      </Layout>
    )
  }
}

AllCatalog.propTypes = {
  data: PropTypes.object,
}

export default AllCatalog

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressCategory(sort: {fields: parent___id}) {
      edges {
        node {
          name
          slug
          parent_element {
            slug
          }
        }
      }
    }
    wordpressPage(slug: {eq: "catalog"}) {
      acf {
        catalog_hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`
