import React, { Component } from "react"
import PropTypes from "prop-types"
import Dropdown from "../components/dropdown"

class Search extends Component {
    constructor(props) {
        super(props)
        const {filters, tag} = props
        var categories = this.prepareCategories(filters);
        
        let initState = {
            isClient: false,
            isOpen: false,
            activeList: "",
            activeFilters: filters ? filters: [],
            activeTag: tag,
            categories: categories.list,
            pageActive: "",
            filterOpen: "",
            year: ["Year"],
            genre: ["Genre"],
            country: ["Country"],
            language: ["Language"]
        }
        if (categories.active != "") {
            initState[categories.active].push(categories.filter)
        }
        
        
        this.state = initState;
        this.handleTab = this.handleTab.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.toggleFilter = this.toggleFilter.bind(this)
    }
    componentDidMount() {
        this.setState({ isClient: true })

    }
    handleReset() {
        const { onReset } = this.props;
        let update = {
            activeTag: "new",
            activeList: "",
            activeFilters: [],
            year: ["Year"],
            genre: ["Genre"],
            country: ["Country"],
            language: ["Language"]
        }
        onReset(update)
        this.setState(update)
    }
    handleSort(event, orderBy, order ) {
        let elm = event.currentTarget
        if (elm == undefined) {
            return;
        }
        let classes = elm ? elm.classList:null
        let update = {}
        if (classes && !classes.contains("active")) {
            const { onSort } = this.props;
            let tag = elm.getAttribute('data-tag')
            update['activeTag'] = ""
            elm.classList.toggle("active")
            onSort(orderBy, order)
        } 
        this.setState(update);
    }
    handleTab(event, tag, orderBy, order) {
        let elm = event.currentTarget
        if (elm == undefined) {
            return;
        }
        let classes = elm ? elm.classList:null
        let update = {}
        if (classes && !classes.contains("active")) {
            const { activeFilters } = this.state
            
            const { onFilter, onSort } = this.props;
            let tag = elm.getAttribute('data-tag')
            update['activeTag'] = tag
            elm.classList.toggle("active")
            // activeFilters.splice(activeFilters.indexOf(this.state[cat][1]), 1)
            // update.activeFilters = activeFilters
            onSort(orderBy, order)

            onFilter(activeFilters, tag)
        } 
        this.setState(update);

    }
    handleClick(event, category) {
        let elm = event.currentTarget
        if (elm == undefined) {
            return;
        } 
        let classes = elm ? elm.classList:null
        let update = {}
        if (classes && classes.contains("active")) {
            
            const { activeFilters, activeTag } = this.state
            const { onFilter } = this.props;
            let cat = elm.getAttribute('data-category')
            // update[cat] = [cat.toUpperCase(), cat]
            // elm.classList.toggle("active")
            // activeFilters.splice(activeFilters.indexOf(this.state[cat][1]), 1)
            // update.activeFilters = activeFilters
            // onFilter(activeFilters, activeTag)
        } 
        // if (classes && classes.contains("dropdown__item")) {
        //     document.getElementsByClassName('filter--' + category)[0].classList.toggle('active')
        // }
        update.isOpen = !this.state.isOpen;
        if (this.state.isOpen) {
            update.activeList = this.state.pageActive;
        } else {
            update.activeList = "active-" + category;
        }
        this.setState(update);
        
    }
    handleText(ev) {
        ev.preventDefault()
        this.setState({
          haveText: ev.currentTarget.textContent
        })
    }
    toggleFilter(event) {
        const { activeFilters, activeTag } = this.state;
        const { onFilter } = this.props;
        let el = event.currentTarget;
        let classes = event ? el.classList:null
        let filter = el.textContent
        let filterSlug = el.getAttribute('data-filter')
        let cat = el.parentNode.getAttribute('data-category')
        let catList = this.state[cat]
        let tag = activeTag != ''? '': '';
        // if(activeFilters.length > 0 && this.state[cat] != cat.toUpperCase()) {
            
        //     activeFilters.splice(activeFilters.indexOf(this.state[cat]), 1)
        // }
        if (activeFilters.indexOf(filterSlug) == -1 || catList.indexOf(filter) == -1) {
            activeFilters.push(filterSlug)
            catList.push(filter)
        } else {
            activeFilters.splice(activeFilters.indexOf(filterSlug), 1)
            catList.splice(catList.indexOf(filter), 1)
        }
        
        el.classList.toggle("active")

        let update = {activeFilters: activeFilters, activeTag: tag }
        update[cat] = catList
        this.setState(update)
        // this.handleClick(event, cat)
        onFilter(activeFilters, tag)
    }
    itemList(props, category ) {
        const { activeFilters } = this.state;
        const list = props.map((item) => {
            var cls = activeFilters.indexOf(item.slug) == -1 ? "dropdown__item" : "dropdown__item active"
            
            return( <a
                onClick={(event) => this.toggleFilter(event)}
                className={cls}
                data-filter={item.slug}
                key={item.slug}>
                    <span>{item.name}</span>
                </a>
            )
        })
        return (
            <div className={category} data-category={category}> { list } </div>
          )
    }    
    prepareCategories(activeFilters) {
        let country = [], language = [], year = [], genre = [], tag = []
        var activeFilter = "", filterText = "";

        this.props.categories.map((obj) => {
            if (obj.node.parent_element == null){
                return;
            }
            switch (obj.node.parent_element.slug){
                case "tag":
                    tag.push(obj.node)
                    break;
                case  "year":
                    year.push(obj.node)
                    break;
                case  "country":
                    country.push(obj.node)
                    break;
                case  "language":
                    language.push(obj.node)
                    break;
                case  "genre":
                    genre.push(obj.node)
                    break;
                default:
            }
            if (activeFilters.indexOf(obj.node.slug) !== -1 ){
                activeFilter = obj.node.parent_element.slug;
                filterText = obj.node.name
            }
        });
        
        language.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
        });
        genre.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
        });
        country.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
        });
        year.sort(function(a, b) {
            return  b.slug - a.slug;
        });
        
        return {active: activeFilter, filter: filterText, list: {language: language, genre: genre, country: country, year: year, tag: tag}}
    }
    render() {
        const {isOpen, categories, activeList, activeFilters, activeTag, genre, year, country, language} = this.state;
        return (
            <React.Fragment key={this.state.isClient}>
                <div className="filter--tabs">
                    {/* <div className={activeTag != "" ? "filter--tab": "filter--tab active"} data-tag="" onClick={(event) => this.handleTab(event, "")}>All</div> */}
                    <div className={activeTag != "" ? "filter--tab": "filter--tab active"} data-tag="" onClick={(event) => this.handleSort(event, "title", "asc")}>All</div>
                    <div className={activeTag != "new" ? "filter--tab": "filter--tab active"} data-tag="new" onClick={(event) => this.handleTab(event, "new", "", "")}>Current</div>
                    <div className={activeTag != "sales" ? "filter--tab": "filter--tab active"} data-tag="sales" onClick={(event) => this.handleTab(event, "sales", "", "")}>Sales</div>
                    <div className={activeTag != "markets-festivals" ? "filter--tab": "filter--tab active"} data-tag="markets-festivals" onClick={(event) => this.handleTab(event, "markets-festivals", "date", "desc")}>Festival Programming</div>
                    <div className="filter--search">
                            <input type="text" className="searchTerm" placeholder="What are you looking for ?  " onChange={this.props.onSearch}/>
                            <div className="filter--submit">
                                <a onClick={this.props.onSearch} className="filter-search">&#xe986;</a>
                            </div>
                    </div>
                </div>
                <div className="filter--bar">
                    <div className="filter--genre filter-menu" data-category="genre" onClick={(event) => this.handleClick(event, "genre")} >
                        <div  className={activeList != "active-genre" ? "filter-button": "filter-button active"}>{genre.map((g, i) => {
                                if (i == 1 ) {
                                    return  " / " + g;
                                }else if (i == 2){
                                    return "..."
                                } else if (i > 2){
                                    return i <= 2;
                                }  else {
                                    return g;
                                } 
                            })}
                        </div>
                    </div>
                    <div className="filter--country filter-menu" data-category="country" onClick={(event) => this.handleClick(event, "country")} >
                        <div  className={activeList != "active-country" ? "filter-button": "filter-button active"}>{country.map((c, i) => {
                                if (i == 1 ) {
                                    return  " / " + c;
                                }else if (i == 2){
                                    return "..."
                                } else if (i > 2){
                                    return i <= 2;
                                }  else {
                                    return c;
                                } 
                            })}</div>
                    </div>
                    <div className="filter--year filter-menu" data-category="year" onClick={(event) => this.handleClick(event, "year")} >
                        <div  className={activeList != "active-year" ? "filter-button": "filter-button active"}>{year.map((y, i) => {
                                if (i == 1 ) {
                                    return  " / " + y;
                                }else if (i == 2){
                                    return "..."
                                } else if (i > 2){
                                    return i <= 2;
                                }  else {
                                    return y;
                                } 
                            })}</div>
                    </div>
                    <div className="filter--language filter-menu" data-category="language" onClick={(event) => this.handleClick(event, "language")} >
                        <div  className={activeList != "active-language" ? "filter-button": "filter-button active"}>{language.map((l, i) => {
                                if (i == 1 ) {
                                    return  " / " + l;
                                }else if (i == 2){
                                    return "..."
                                } else if (i > 2){
                                    return i <= 2;
                                }  else {
                                    return l;
                                } 
                            })}</div>
                    </div>
                    {activeFilters.length > 0 ? <div className="filter-menu" onClick={(event) => this.handleReset()} >
                        <div className="filter-button filter-button--clear active">Clear All</div>
                    </div>: null}
                    
                </div>
                <div className={isOpen? "catalog-filters " + activeList : "catalog-filters"}>
                    {this.itemList(categories.country, "country")}
                    {this.itemList(categories.language, "language")}
                    {this.itemList(categories.year, "year")}
                    {this.itemList(categories.genre, "genre")}    
                </div>
                  
            </ React.Fragment>
        );
    }
}

export default Search;

Search.propTypes = {
    context: PropTypes.object,
    categories: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object)
}
