import React, { Component } from "react"
import PropTypes from "prop-types"


class Dropdown extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        isOpen: false,
        haveText: ""
      }
    }
  
    render() {
      const {isOpen, haveText} = this.state;
  
      return (
        <div
          className={isOpen ? "dropdown active" : "dropdown"}
          onClick={this.handleClick} >
          <div className="dropdown__text">
            {!haveText ? "Select Race" : haveText}
          </div>
          {this.itemList(this.props.data)}
        </div>
      )
    }
  
    handleClick = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  
    handleText = (ev) => {
      this.setState({
        haveText: ev.currentTarget.textContent
      })
    }
  
    itemList = props => {
      const list = props.map((item) => (
        <div
          onClick={this.handleText}
          className="dropdown__item"
          key={item.slug}>
          {item.name}
        </div>
      ));
  
      return (
        <div className="dropdown__items"> { list } </div>
      )
    }
  
}

Dropdown.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};
export default Dropdown;
