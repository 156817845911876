import React from "react"

const Loader = () => (
    <div className='loader loader3'>
        <div>
        <div>
            <div>
            <div>
                <div>
                <div></div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
)

export default Loader
